:root{
    --heart-comp-text-back:rgb(1,1,1,0.1);
    --heart-comp-like-back:rgba(201,65,98,1);
    --color-normal:rgb(168,160,144);
    --color-fighting:rgb(160,80,56);
    --color-flying:rgb(152,168,240);
    --color-poison:rgb(176,88,160);
    --color-ground:rgb(234,214,164);
    --color-rock:rgb(184,160,88);
    --color-bug:rgb(168,184,32);
    --color-ghost:rgb(96,96,176);
    --color-steel:rgb(168,168,192);
    --color-fire:rgb(240,80,48);
    --color-water:rgb(56,153,248);
    --color-grass:rgb(120,200,80);
    --color-electric:rgb(248,208,48);
    --color-psychic:rgb(248,112,160);
    --color-ice:rgb(88,200,224);
    --color-dragon:rgb(160,80,56);
    --color-dark:rgb(122,88,72);
    --color-fairy:rgb(231,159,231);
    --color-unknown:rgb(176,88,160);
    --color-shadow:rgb(66,66,66);
    --color-grass-p: #94f39a;
    --color-fire-p: #af7f7f;
    --color-water-p: #76bedf;
    --color-bug-p: #f7b559;
    --color-normal-p: #524c4c;
    --color-fighting-p: #dfceae;
    --color-poison-p: #8f8cda;
    --color-electric-p: #f5e077;
    --color-ground-p: #f0c091;
    --color-fairy-p: #e7a4f3;
    --color-psychic-p: #e3e75f;
    --color-rock-p: #f7a9a9;
    --color-ghost-p: #59add1;
    --color-pink: #128924;
    /* My own variables */
    --color-white: #fff;
    --color-dark: #000;
    --color-darkGrey: #696969;
    --color-lightGrey: #808080;
    --color-blue: #2C71B8;
    --color-yellow: #FFCC03;
    --font-size-s: 11px;
    --font-size-m: 14px;
    --radius-m: 7px;
}
  
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    box-sizing: border-box;
    margin:0;
    padding:0;
    font-family: 'Rubik', sans-serif;
}
main{
    min-height: 100vh;
    width: 100%;
    margin-bottom: 20px;
}

.dontshowlightdom {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }